
<template>
  <div>
    <el-dialog v-bind="$attrs" v-on="$listeners"  @close="onClose"
    title="添加仓库"
    :visible.sync="visible"
    width="740px"
    >

      <el-form ref="form" :model="row" :rules="rules" size="mini" label-width="120px"      >
<el-row>
        <el-col :span="12">
                <el-form-item label="仓库名称" prop="name">
              <el-input v-model="row.name" placeholder="请输入仓库名称" clearable
                :style="{width: '200px'}"></el-input>
            </el-form-item>
        </el-col>


        <el-col :span="12">
                <el-form-item label="仓库编码" prop="code">
              <el-input v-model="row.code" placeholder="请输入仓库编码" clearable
                :style="{width: '200px'}"></el-input>
            </el-form-item>
        </el-col>
            </el-row>
            <el-row>

        <el-col :span="12">
                <el-form-item label="仓库地址" prop="address">
              <el-input v-model="row.address" placeholder="请输入仓库地址" clearable
                :style="{width: '200px'}"></el-input>
            </el-form-item>
        </el-col>

        <el-col :span="12">
                <el-form-item label="负责人名称" prop="owner">
              <el-input v-model="row.owner" placeholder="请输入负责人名称" clearable
                :style="{width: '200px'}"></el-input>
            </el-form-item>
        </el-col>
            </el-row>
            <el-row>

        <el-col :span="12">
                <el-form-item label="负责人电话" prop="phone">
              <el-input v-model="row.phone" placeholder="请输入负责人电话" clearable
                :style="{width: '200px'}"></el-input>
            </el-form-item>
        </el-col>

        <el-col :span="12">
                <el-form-item label="发货人姓名" prop="senderName">
              <el-input v-model="row.senderName" placeholder="请输入发货人姓名" clearable
                :style="{width: '200px'}"></el-input>
            </el-form-item>
        </el-col>
            </el-row>
            <el-row>

        <el-col :span="12">
                <el-form-item label="发货人电话" prop="senderPhone">
              <el-input v-model="row.senderPhone" placeholder="请输入发货人电话" clearable
                :style="{width: '200px'}"></el-input>
            </el-form-item>
        </el-col>

        <el-col :span="12">
                <el-form-item label="发货省份" prop="senderProvince">
              <el-input v-model="row.senderProvince" placeholder="请输入发货省份" clearable
                :style="{width: '200px'}"></el-input>
            </el-form-item>
        </el-col>
            </el-row>
            <el-row>

        <el-col :span="12">
                <el-form-item label="发货城市" prop="senderCity">
              <el-input v-model="row.senderCity" placeholder="请输入发货城市" clearable
                :style="{width: '200px'}"></el-input>
            </el-form-item>
        </el-col>

        <el-col :span="12">
                <el-form-item label="发货区县" prop="senderDistrict">
              <el-input v-model="row.senderDistrict" placeholder="请输入发货区县" clearable
                :style="{width: '200px'}"></el-input>
            </el-form-item>
        </el-col>
            </el-row>
            <el-row>

        <el-col :span="12">
                <el-form-item label="发货地址" prop="senderAddress">
              <el-input v-model="row.senderAddress" placeholder="请输入发货地址" clearable
                :style="{width: '200px'}"></el-input>
            </el-form-item>
        </el-col>

        </el-row>

      </el-form>
      <div slot="footer">
        <el-button @click="close">取消</el-button>
        <el-button type="primary" @click="handleConfirm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getEnums, uploadFile } from "@/api/common"
import { addWmsWarehouse } from "@/api/wms/wmsWarehouse"

import { listWmsProperty } from "@/api/wms/wmsProperty"


export default {
  inheritAttrs: false,
  components: { },
  props: [],
  data() {
    return {
      visible: false,
      row: {
            name: null,
            priority: null,
            type: 0,
            code: null,
            propertyName: null,
            propertyId: null,
            address: null,
            owner: null,
            phone: null,
            qmCustomerId: null,
            qmTargetAppKey: null,
            ktyCustomerId: null,
            ktyWarehouseId: null,
            senderName: null,
            senderPhone: null,
            senderProvince: null,
            senderCity: null,
            senderDistrict: null,
            senderAddress: null,
            disabled: 0,
            creatorType: null,
      },
      rules: {
            name: [
                { required: true, message: '请输入仓库名称!'},
            ],
            priority: [
                { required: true, message: '请输入出货优先级!'},
            ],
            type: [
                { required: true, message: '请输入仓库类型!'},
            ],
            senderName: [
                { required: true, message: '请输入发货人姓名!'},
            ],
            senderPhone: [
                { required: true, message: '请输入发货人电话!'},
                { pattern: /^[1-9]\d{5}$/, message: '请输入正确的邮政编码!'},
            ],
            senderPhone: [
                { required: true, message: '请输入发货人电话!'},
            ],
            senderProvince: [
                { required: true, message: '请输入发货省份!'},
            ],
            senderCity: [
                { required: true, message: '请输入发货城市!'},
            ],
            senderDistrict: [
                { required: true, message: '请输入发货区县!'},
            ],
            senderAddress: [
                { required: true, message: '请输入发货地址!'},
            ],
            creatorType: [
                { required: true, message: '请输入仓库创建端,同Application!'},
            ],
      },
        enums: {},
        enumMaps: {},
        optionsOfWmsProperty: [],

    }
  },
  computed: {},
  watch: {},
  created() {
    this.initEnums()
    this.listWmsProperty()
  },
  mounted() {},
  methods: {
      initEnums() {
          let names = []
          names.push('WmsWarehouseTypeEnum')
          const param = {
              names: names
          }
          getEnums(param).then(res=>{
              if(res.code===200) {
                this.enums = res.data.enums
                Object.keys(this.enums).forEach(key => {
                  let map = {}
                  this.enums[key].forEach(obj => map[obj.value] = obj.name)
                  this.enumMaps[key] = map
                })
              } else {
                  this.$message.error(res.message)
              }
          })
    },
    onOpen() {
        this.visible = true
    },
    onClose() {
    },
    close() {
      this.$refs['form'].resetFields()
      this.visible = false
    },
    success(result) {
      this.$emit('success', result)
    },

    listWmsProperty(val) {
        const param = {
            name: val,
            pageSize: 20,
        }
        listWmsProperty(param).then(res => {
            if(res.code === 200) {
                this.optionsOfWmsProperty = res.data
            } else {
                this.$message.error(res.message)
            }
        })
    },

    upload(param){
        const formData = new FormData()
        formData.append('file', param.file)
        uploadFile(formData).then(res => {
            if(res.code === 200){
                param.onSuccess(res.data.url)
                this.$message.success("上传成功")
            }else{
                this.$message.error(res.message)
            }
        }).catch(response => {
            console.log(response)
            this.$message.error('上传失败')
            param.onError()
        })
    },
    handleConfirm() {
      this.$refs['form'].validate(valid => {
        if (!valid) return;
        const param = this.row
        this.loading = true
        addWmsWarehouse(param).then(res => {
            this.loading = false
            if(res.code===200) {
                this.$message.success(res.message)
                this.success(res.data)
                this.close()
            } else {
                this.$message.error(res.message)
            }
        }).catch(err=>{
            this.loading = false
        })
      })
    },
  }
}

</script>
<style scoped>
.el-select {
    width: 200px;
}
.avatar {
    max-width: 200px;
    max-height: 200px
}
</style>
