<template>
  <div>
    <el-dialog v-bind="$attrs" v-on="$listeners" @close="onClose"
    title="仓库详情"
    :visible.sync="visible"
    width="600px"
    >

      <el-descriptions class="margin-top" :column="2"
      border>


            <el-descriptions-item label="仓库ID">
            {{ row.id }}
            </el-descriptions-item>

            <el-descriptions-item label="仓库名称">
            {{ row.name }}
            </el-descriptions-item>

            <el-descriptions-item label="出货优先级">
            {{ row.priority }}
            </el-descriptions-item>

            <el-descriptions-item label="仓库类型" v-if="enumMaps['WmsWarehouseTypeEnum']">
                 {{enumMaps['WmsWarehouseTypeEnum'][row.type]}}
            </el-descriptions-item>

            <el-descriptions-item label="仓库编码">
            {{ row.code }}
            </el-descriptions-item>

            <el-descriptions-item label="仓库属性">
            {{ row.propertyName }}
            </el-descriptions-item>


            <el-descriptions-item label="仓库地址">
            {{ row.address }}
            </el-descriptions-item>

            <el-descriptions-item label="负责人名称">
            {{ row.owner }}
            </el-descriptions-item>

            <el-descriptions-item label="负责人电话">
            {{ row.phone }}
            </el-descriptions-item>

            <el-descriptions-item label="发货人姓名">
            {{ row.senderName }}
            </el-descriptions-item>

            <el-descriptions-item label="发货人电话">
            {{ row.senderPhone }}
            </el-descriptions-item>

            <el-descriptions-item label="发货省份">
            {{ row.senderProvince }}
            </el-descriptions-item>

            <el-descriptions-item label="发货城市">
            {{ row.senderCity }}
            </el-descriptions-item>

            <el-descriptions-item label="发货区县">
            {{ row.senderDistrict }}
            </el-descriptions-item>

            <el-descriptions-item label="发货地址">
            {{ row.senderAddress }}
            </el-descriptions-item>

              <el-descriptions-item label="是否禁用">
                {{row.disabled == 0 ? '否': '是'}}
              </el-descriptions-item>



            <el-descriptions-item label="创建时间">
            {{ row.createTime }}
            </el-descriptions-item>




      </el-descriptions>


      <div slot="footer">
        <el-button @click="close">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getEnums } from "@/api/common"
import { detailWmsWarehouse } from "@/api/wms/wmsWarehouse"

export default {
  inheritAttrs: false,
  components: {},
  props: [],
  data() {
    return {
        visible: false,
        row: {
            id: '',
            name: '',
            priority: '',
            type: '',
            code: '',
            propertyName: '',
            propertyId: '',
            address: '',
            owner: '',
            phone: '',
            qmCustomerId: '',
            qmTargetAppKey: '',
            ktyCustomerId: '',
            ktyWarehouseId: '',
            senderName: '',
            senderPhone: '',
            senderProvince: '',
            senderCity: '',
            senderDistrict: '',
            senderAddress: '',
            disabled: '',
            creatorType: '',
            createTime: '',
        },
        enums: {},
        enumMaps: {},
    }
  },
  computed: {},
  watch: {},
  created() {
    this.initEnums()
  },
  mounted() {},
  methods: {
      initEnums() {
          let names = []
          names.push('WmsWarehouseTypeEnum')
          const param = {
              names: names
          }
          getEnums(param).then(res=>{
              if(res.code===200) {
                this.enums = res.data.enums
                Object.keys(this.enums).forEach(key => {
                  let map = {}
                  this.enums[key].forEach(obj => map[obj.value] = obj.name)
                  this.enumMaps[key] = map
                })
              } else {
                  this.$message.error(res.message)
              }
          })
    },
    detail() {
        const param = {
            id: this.row.id
        }
        detailWmsWarehouse(param).then(res=>{
          if(res.code === 200) {
            this.row = res.data
          } else {
            this.$message.error(res.message)
          }
        })
    },
    onOpen(row) {
        console.log("onOpen", row)
        this.visible = true
        this.row = row
        this.detail()
    },
    onClose() {
    },
    close() {
      this.visible = false
    },
  }
}

</script>
<style scoped>
.avatar {
    max-width: 200px;
    max-height: 200px
}
</style>
